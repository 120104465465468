.oneServiceHolder{
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.oneServiceHolder:hover > .overlay{
    display: flex;
    /* opacity: 0.8; */
}

.oneServiceHolder:hover > .textTransition{
    display: flex;
}

.oneServiceHolder img{
    max-width: 500px;
    width: 100%;
}

.textTransition{
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    width: 90%;
    height: 100%;
    color: black;
    margin: 0px auto;
    font-size: 24px;
    line-height: 1.3;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
}

.textTransition h3{
    font-size: 27px
}

.overlay{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: orange;
    animation: opacity-animation 0.2s forwards;
}

@keyframes opacity-animation {
    0%    { opacity: 0; }
  100%  { opacity: 0.8; }
}

@media screen and (max-width:700px) {
    .oneServiceHolder{    
    max-width: 700px;
    width: 100%;
    max-height: 300px;
    }
    .oneServiceHolder img{
        max-width: 700px;
        height: 100%;
        object-fit: cover;
        object-position: 20% 18%;
    }
    .textTransition h3{
        font-size: 26px;
    }
}


