.footerHolder{
    width: 100%;
    margin: 0px auto;
    background-color: #161616;
}

.footerMain{
    margin: 0px auto;
    max-width: 1300px;
    width: 100%;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: white;
}

.footerMain h2{
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 500;
}

.footerLeft{
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 26px;
}

.footerMiddle{
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
}

.footerMiddle p{
    margin: 0;
    cursor: pointer;
}

.socialMedia{
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.smallIcons{
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

@media screen and (max-width:1200px) {
    .footerTogheter{
        max-width: 300px;
        width: 100%;
    }
    .footerMain{
        justify-content: center;
        gap: 35px;
    }
}

@media screen and (max-width:900px) {
    .footerMain{
        width: 95%;
    }
}

@media screen and (max-width:600px) {
    .footerMain{
        flex-direction: column;
    }
    .footerLeft{
        max-width: 600px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .footerRight{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0px auto;
    }
    .smallIcons p{
        max-width: 300px;
        width: 100%;
        text-align: left;
    }
    .footerMiddle{
    align-items: center;
    margin: 0px auto;
    }
    .footerMiddle h2{
        margin-bottom: 0px;
    }
}









