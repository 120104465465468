.languagesAndFlags{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    max-width: 100px;
    width: 100%;
    margin-left: 25px;
}

.serbianFlag{
    width: 30px;
    cursor: pointer;
}

.englishFlag{
    width: 30px;
    cursor: pointer;
}

.selected{
    border: 2px solid black;
    border-radius: 50%;
}

@media screen and (max-width: 1200px) {
    .languagesAndFlags{
        margin-left: none;
    }
}

@media screen and (max-width: 600px) {
    .serbianFlag, .englishFlag{
        width: 20px;
    }
}