.madeByHolder{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    line-height: 1.8;
    font-weight: 400;
    color: #4d4d4d;
    background: #111111;
    padding: 15px 0px;
}

.madeByHolder span{
    color: orange;
}

@media screen and (max-width:630px) {
    .madeByHolder{
        /* width: 95%; */
        text-align: center;
    }
}