.blogPostsHolder{
    padding: 50px 0px;
    width: 100%;
    background-color: white;
}

.blogPostsMain{
    margin: 0px auto;
   /*  max-width: 1300px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blogPostsGrid{
    width: 95%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items:center;
    align-content: center;
}

.blogPostsUpper{
    text-align: center;
    padding: 30px 0px;
}

.blogPostsUpper h4{
    color: #ffa62b;
    margin-bottom: 0;
    text-transform: uppercase;
}

.blogPostsUpper h1{
    line-height: 1.5;
    color: black;
    font-weight: 400;
    font-size: 70px;
    margin: 0px auto;
}

@media screen and (max-width:1200px) {
    .blogPostsGrid{
        grid-template-columns: repeat(3, 1fr);
    } 
    
}

@media screen and (max-width:900px) {
    .blogPostsUpper h1{
        font-size: 43px;
    }
    .blogPostsGrid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:700px) {
    .blogPostsGrid{
        grid-template-columns: repeat(1, 1fr);
}
}
