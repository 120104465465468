.testimonialsHolder{
    width: 100%;
    margin: 0px auto;
    background-color: white;
}

.testimonialsMain{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.testimonialsUpper{
    text-align: center;
    padding: 30px 0px;
}

.testimonialsUpper h4{
    color: #ffa62b;
    margin-bottom: 0;
    text-transform: uppercase;
}

.testimonialsUpper h1{
    line-height: 1.5;
    color: black;
    font-weight: 400;
    font-size: 70px;
    margin: 0px auto;
}

.testimonialsBottom{
    max-width: 1000px;
    width: 100%;
    /* height: 100%; */
}

.testimonialsBottom img{
    max-width: 150px;
    width: 100%;
    border-radius: 50%;
    border: 4px solid #ffa62b;
}

.MuiPaper-root{
    box-shadow: 0px 0px 3px 0px rgb(210, 210, 210)!important;
    padding-top: 20px;
}

@media screen and (max-width:900px) {
    .testimonialsUpper h1{
        font-size: 43px;
    }
}

