.postByIdHolder{
    margin: 0px auto;
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    gap: 10px;
}

.postByIdHolder img{
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    margin-top: 50px;
}

.postByIdText{
    width: 90%;
    border-radius: 5px;
    border-radius: 0px 0px 10px 10px;
}