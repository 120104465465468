.postHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
}

.postHolder img{
    max-width: 400px;
    width: 100%;
}

.date{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    max-width: 150px;
    width: 100%;
}

.date h6{
    margin: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: #4d4d4d;
}

.calendarImage{
    max-width: 15px!important;
    width: 100%!important;
}

.postMain{
    color: #ffa62b;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 25px;
}

.postMain p{
    font-size: 17px; 
    line-height: 1.8;
    font-weight: 500;
    color: #4d4d4d;
    max-width: 280px;
    width: 100%;
    /* margin-bottom: 3rem; */
}

@media screen and (max-width:700px) {
    .postMain{
        width: 95%;
    }
    .postMain p{
        max-width: 700px;
        width: 100%;
        margin-bottom: 30px;
    }
   .postHolder{
        max-width: 800px;
        width: 95%;
    }
    .postHolder img{
        max-width: 700px;
    }
}

@media screen and (max-width:600px) {
    .postHolder img{
        object-fit: cover;
        object-position: 15% 85%;
        max-height: 300px;
    }
}

