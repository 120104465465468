.homeViewHolder{
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ffa62b;
}

.homeViewMain{
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.elevate{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.homeViewMain h2{
    font-size: 43px;
    margin-bottom: 0;
    font-weight: 500;
}

.nameAndImage{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;
}

.nameAndImage h1{
    text-align: center;
    font-weight: 700;         
    font-size: 8vw;
    line-height: 1;
    letter-spacing: 10px;
    margin-bottom: 0;
    margin-top: 0;
}

@media screen and (max-width:900px) {
    .elevate{
        flex-direction: column;
        text-align: center;
        gap: 0px;
    }
    .elevateDown{
        margin-top: 0;
    }
}

@media screen and (max-width:700px) {
    .homeViewMain{
        gap: 30px;
    }
    .homeViewMain h2{
        font-size: 30px;
    }
    .nameAndImage{
        gap: 30px
    }
}




