.textBelowImage{
    max-width: 600px;
    width: 100%;
    line-height: 1.5;
    font-weight: 400;
    color: #4d4d4d;
    text-align: center;
    margin: 20px auto;
}

.nameBelowImage{
    line-height: 1.5;
    color: black;
    font-weight: 400;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
}

.workingTitle{
    color: #ffa62b;
    margin-top: 0;
    font-weight: 500;
}
