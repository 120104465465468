.aboutHolder{
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 1;
    background-color: white;
}

.aboutMain{
    max-width: 1300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.imageAndShortText{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    padding: 8rem 0;
}

.imageAndShortText h2{
    text-align:start;
    max-width: 750px;
    width: 100%;
    font-size: 40px;
    line-height: 1.5;
    color: black;
    font-weight: 400;
    margin: 0;
}

.biggerImageAndLongerText{
    max-width: 1390px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7rem;
}

.justText{
    max-width: 750px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.justText h1 {
    font-size: 70px;
    font-weight: 400;
    text-decoration: underline;
    -webkit-text-decoration-color: #ffa62b;
    text-decoration-color: #ffa62b;
}

.justText p{
    font-size: 17px;
    line-height: 1.8;
    font-weight: 400;
    color: black;
    margin-top: 0;
    margin-bottom: 1rem;
}

@media screen and (max-width:1200px) {
    .biggerImageAndLongerText {
        justify-content: center;
        gap: 2rem;
    }
    .justText{
        max-width: 400px;
    }
    .justText h1{
        font-size: 43px;
    }
}

@media screen and (max-width:1000px) {
    .biggerImageAndLongerText{
        flex-direction: column;
    }
    .mMSolutionsButton{
        margin: 0px auto;
    }
    .justText{
        max-width: 900px;
        width: 90%
    }
    .justText h1{
        text-align: center;
    }

}

@media screen and (max-width:900px) {
    .imageAndShortText{
        width: 90%;
        padding: 4rem 0
    }
}

@media screen and (max-width:600px) {
    .imageAndShortText{
        flex-direction: column;
        align-items: center;
        padding: 3rem 0;
    }
    .imageAndShortText h2{
        font-size: 30px;
        text-align: center;
    }
    .justText{
        text-align: center;
    }
}

.visibleNone{
    opacity: 0;
}
