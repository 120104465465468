.serviceOfferingHolder{
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;
}

.serviceOfferingMain{
    max-width: 1300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem 0;
}

.upperText{
    text-align: center;
}

.upperText h4{
    color: #ffa62b;
    margin-bottom: 0;
    text-transform: uppercase;
}

.upperText h1{
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    font-size: 70px;
    margin: 0px auto;
}

.upperText p{
    font-size: 17px;   
    line-height: 1.8;
    font-weight: 500;
    color: #4d4d4d;
    max-width: 800px;
    width: 100%;
    margin-bottom: 3rem;
}

.bottomImages{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;
}

@media screen and (max-width:900px) {
    .upperText h1{
        font-size: 43px;
    }
    .upperText p{
        margin-top: 0;
    }
    .bottomImages{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width:800px) {
    .serviceOfferingMain p{
        text-align: center;
        line-height: 1.6;
        max-width: 700px;
    }
}

@media screen and (max-width:700px) {
    .serviceOfferingMain p{
        width: 90%;
        margin: 0px auto;
        padding: 15px 0px;
    }

    .bottomImages{    
    width: 100%;
    /* gap: 5px; */
    }
}

@media screen and (max-width:600px) {
    .bottomImages{
        grid-template-columns: repeat(1, 1fr);
        width: 95%;
    }
}





