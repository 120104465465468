.oneStatisticHolder{
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.oneStatisticMain{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 192px;
    width: 100%;
}

.oneStatisticMain h3{
    margin: 0;
    font-size: 50px;
    font-weight: 600;
    color: #fff;
    line-height: 1;
}

.oneStatisticMain p{
    font-size: 14px;
    font-weight: 600;
    color: #ffa62b;
    /* text-transform: uppercase; */
}

.smallImages{
    max-width: 70px;
    width: 100%;
    border-radius: 50%;
}




